<script lang="ts" setup>
import type { FetchError } from 'ofetch'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import BasicInput from '~/components/form-elements/BasicInput.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import SubscriptionForm from '~/enums/subscription-form'
import ApiErrorHandler from '~/helpers/api-error-handler'
import { useAuthStore } from '~/store/auth'

const model = defineModel<boolean>()

const props = defineProps<{
  subscriptionForm: SubscriptionForm
}>()

type Form = {
  email: string | null
}

const messages = {
  emailFieldCannotBeEmpty: 'Email не введён.',
  emailIsSent: 'Email отправлен.',
  failedToGetUser: 'Не удалось получить пользователя.',
  failedToSendEmail: 'Не удалось отправить email.'
}

const { $apiHelper, $toast } = useNuxtApp()
const authStore = useAuthStore()

const form = ref<Form>({ email: null })
const isSubscriberCreating = ref(false)
const isUserDataLoading = ref(false)
const isUserDataLoadingError = ref(false)

const buttonTitle = computed(
  () => props.subscriptionForm === SubscriptionForm.Guide ? 'Получить гайд' : 'Создать напоминание'
)
const title = computed(() => {
  switch (props.subscriptionForm) {
    case SubscriptionForm.BlackFriday:
      return 'Создание напоминания о\xA0«Чёрной пятнице»'
    case SubscriptionForm.GreenFriday:
      return 'Создание напоминания о\xA0«Зелёной пятнице»'
    case SubscriptionForm.Guide:
      return 'Получите бесплатный гайд «Поддержка в\xA0беременность»!'
    default:
      return null
  }
})

async function beforeOpen (): Promise<void> {
  if (authStore.isUser) {
    await loadUserData()
  }
}

function close (): void {
  model.value = false
}

async function createSubscriber (): Promise<void> {
  const { email } = form.value
  const { emailFieldCannotBeEmpty, failedToSendEmail, emailIsSent } = messages

  if (!email) {
    $toast.error(emailFieldCannotBeEmpty)

    return
  }

  isSubscriberCreating.value = true

  try {
    await $apiHelper.subscribers.createSubscriber(email, props.subscriptionForm)

    $toast.success(emailIsSent)

    close()
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as FetchError, failedToSendEmail)
  }

  isSubscriberCreating.value = false
}

async function loadUserData (): Promise<void> {
  isUserDataLoading.value = true
  isUserDataLoadingError.value = false

  try {
    const userResponse = await $apiHelper.users.getUser(authStore.userId)
    form.value.email = userResponse.data.attributes.email || null
  } catch (error) {
    console.error(error)

    isUserDataLoadingError.value = true

    $toast.error(messages.failedToGetUser)
  }

  isUserDataLoading.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model" @before-open="beforeOpen">
    <template #title>
      {{ title }}
    </template>

    <Preloader v-if="isUserDataLoading" />
    <Notification v-else-if="isUserDataLoadingError" is-small status="error">
      {{ messages.failedToGetUser }}
    </Notification>
    <template v-else>
      <Notification
        v-if="subscriptionForm === SubscriptionForm.BlackFriday || subscriptionForm === SubscriptionForm.GreenFriday"
        class="mb-6"
        is-small
        status="info"
      >
        Чтобы мы&nbsp;напомнили об&nbsp;акции, оставьте, пожалуйста, свой email.
      </Notification>
      <template v-else-if="subscriptionForm === SubscriptionForm.Guide">
        <p>Беременность&nbsp;— особенное время. Поэтому нутрициологи iTAB разобрались, чем вы&nbsp;можете поддержать себя.</p>

        <p>Оставьте свою почту и&nbsp;получите полезный гайд с&nbsp;рекомендациями по&nbsp;добавкам и&nbsp;образу жизни, чтобы сделать этот период комфортным и&nbsp;радостным.</p>
      </template>

      <BasicInput v-model="form.email" class="mb-6" is-required label-text="Email" />

      <BasicButton color="green" :is-loading="isSubscriberCreating" :title="buttonTitle" @click="createSubscriber" />

      <Agreement :button-title="buttonTitle" class="mt-6" />
    </template>

    <template #action>
      <a href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>
